import styled from 'styled-components';

export const ErrorStyles = styled.div`
  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-width: 100vw;
    min-height: 100vh;
    text-align: center;
    gap: 40px;
  }
  
  .iconWrapper {
    @media (max-width: 600px) {
      svg {
        width: 100%;
        height: auto;
      }
    }
  }
  
  .error {
    color: var(--orange-1, #FC812B);
    font-size: 44px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.88px;
  }
  
  .textWrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
    max-width: 544px;
  }
  
  .buttonsWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    gap: 20px;
    
    @media (min-width: 600px) {
      flex-direction: row;
    }

    .button {
      width: 100%;

      @media (min-width: 1024px) {
        max-width: 200px;
      }
    }
  }
`;
