import React from 'react';
import logo from './logo.svg';
import './App.css';
import {
  createBrowserRouter, createRoutesFromElements, Navigate, Route, RouterProvider,
} from 'react-router-dom';
import Error403 from './pages/errors/403/Error403';
import Error404 from './pages/errors/404/Error404';
import Error500 from './pages/errors/500/Error500';
import FormPage from './pages/formPage/FormPage';
import ThankYou from './pages/thankYou/ThankYou';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/:id" element={<FormPage />} />
      <Route path="/embed/:id" element={<FormPage />} />
      <Route path="/thank-you" element={<ThankYou />} />

      <Route path="/error/403" element={<Error403 />} />
      <Route path="/error/404" element={<Error404 />} />
      <Route path="/error/500" element={<Error500 />} />

      <Route path="*" element={<Navigate to="/error/404" replace />} />
    </>,
  ),
);

function App() {
  return (
    <>
      <ToastContainer
        limit={10}
        style={{
          maxWidth: '630px',
          width: '100%',
          paddingLeft: '16px',
          paddingRight: '16px',
          paddingTop: '20px',
        }}
        toastClassName="toastifyHideButton toastifyTextCenter"
      />

      <RouterProvider router={router} />
    </>
  );
}

export default App;
