import React from 'react';
import { TextButtonProps } from '../../../enteties/button/ButtonProps';
import Loader from '../../loader/Loader';
import { TFormDesign } from '../../../enteties/types/forms.types';
import { convertColorToCss } from '../../../utils/convertRGBAToCssColor';
import { ButtonStyles } from './ButtonStyles';

const buttonLoaderColors = {
  grey: '#8C8C8C',
  mainFilled: '#FDFDFD',
  mainNotFilled: '#FDB17B',
  red: '#FF7875',
};

interface ButtonProps {
  styles: Pick<TFormDesign, 'submit_button'>;
  text: string;
  isLoading?: boolean;
  error?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
}

function Button({
  error,
  styles,
  text,
  disabled,
  isLoading,
  fullWidth,
}: ButtonProps) {
  return (
    <ButtonStyles
      styles={styles}
      className={`button textSemiBold16 ${isLoading ? 'loading' : ''}`}
      style={fullWidth ? { width: '100%' } : {}}
      disabled={disabled}
      type="submit"
    >
      {isLoading && <Loader size={16} color={convertColorToCss(styles.submit_button.text_color)} />}

      {text && text}

      {isLoading && <Loader size={16} color={convertColorToCss(styles.submit_button.text_color)} />}
    </ButtonStyles>
  );
}

export default Button;
