import ApiResponse from '../api/entities/ApiResponse';

async function workWithResponse<T>(request: () => Promise<ApiResponse<T>>, withRedirectToError = false): Promise<{ data: T | null, error: string | null, statusCode: number }> {
  const response = await request();

  if (withRedirectToError) {
    if (response.statusCode >= 500 && response.statusCode < 600) {
      window.location.href = '/error/500';
    }

    if (response.statusCode >= 400 && response.statusCode < 500) {
      window.location.href = `/error/${response.statusCode}`;
    }
  }

  if (response.statusCode >= 200 && response.statusCode < 300) {
    return { data: response.data, error: null, statusCode: response.statusCode };
  }
  const errorMessage = (response.data as {message: string})?.message || 'Unknown error occurred';
  return { data: null, error: errorMessage, statusCode: response.statusCode };
}

export default workWithResponse;
