import styled from 'styled-components';
import { RGBA } from '../../../enteties/types/forms.types';
import { isColorDark } from '../../../utils/darkenColor';

interface Props {pageColor: RGBA}

export const SignatureStyles = styled.a<Props>`
  display: flex;
  align-items: center;
  gap: 8px;

  padding: 4px;
  border-radius: 4px;
  
  color: ${(props) => (isColorDark(props.pageColor) ? '#FFF' : '#000')};
  
  svg {
    height: 25px;
    width: auto;
    
    .letters {
      fill: ${(props) => (isColorDark(props.pageColor) ? '#FFF' : '#000')};
    }
  }
`;
