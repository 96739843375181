import styled from 'styled-components';
import { convertColorToCss } from '../../../utils/convertRGBAToCssColor';
import { InputFormDesignProps } from '../../../enteties/types/forms.types';

interface Props {
  styles: InputFormDesignProps
}

export const TextAreaStyles = styled.div<Props>`
  display: flex;
  flex-direction: column;
  gap: 4px;
  
  span {
    display: flex;
    flex-direction: column;
    gap: 3px;
    color: ${(props) => convertColorToCss(props.styles.label_color)};
    font-weight: 600;
  }
  
  textarea {
    width: 100%;
    
    font-size: 16px;
    background-color: inherit;
    box-sizing: border-box;
    padding: 10px 8px;
    transition: 0.3s all;
    font-family: 'Inter', sans-serif;
    outline: none;
    resize: none;
    
    border-radius: ${(props) => `${props.styles.border_radius}px`};
    border: 1px solid ${(props) => convertColorToCss(props.styles.border_color)};
    background: ${(props) => convertColorToCss(props.styles.background_color)};
    color: ${(props) => convertColorToCss(props.styles.text_color)};

    &::placeholder {
      font-family: 'Inter', sans-serif;
      font-size: 14px;
      line-height: 22px;
      color: ${(props) => convertColorToCss(props.styles.placeholder_color)};
    }
  }
`;
