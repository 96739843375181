import React from 'react';
import { SignatureStyles } from './SignatureStyles';
import Logo from '../../../assets/Logo';
import { RGBA } from '../../../enteties/types/forms.types';

function Signature({ pageColor }: {pageColor: RGBA}) {
  return (
    <SignatureStyles
      pageColor={pageColor}
      target="_blank"
      href={`${process.env.REACT_APP_QUST_CRM_URL}`}
      rel="noreferrer"
      className="textBase14 signature"
    >
      Made using

      <Logo />
    </SignatureStyles>
  );
}

export default Signature;
