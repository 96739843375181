import styled from 'styled-components';
import { convertColorToCss } from '../../../utils/convertRGBAToCssColor';
import { TFormDesign } from '../../../enteties/types/forms.types';

interface FormStylesProps {
  styles: TFormDesign;
}

export const FormStyles = styled.form<FormStylesProps>`
    margin: auto;
    
    max-width: 600px;

    padding: 24px;

    border-radius: ${(props) => `${props.styles.form.border_radius}px`};
    overflow: hidden;
    border: 1px solid var(--Stroke, #EEE);
    background: ${(props) => convertColorToCss(props.styles.form.background_color)};
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.12);

    .formHead {
      display: flex;
      flex-direction: column;
      gap: 6px;

      margin-bottom: 10px;

      text-align: center;

      .title {
        color: ${(props) => convertColorToCss(props.styles.title.color)};

        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 34px;
      }

      .description {
        color: ${(props) => convertColorToCss(props.styles.description.color)};
      }

      .image {
        max-width: 100%;
        max-height: 100%;
        width: auto;
        height: auto;

        object-fit: contain;
        border-radius: ${(props) => `${props.styles.image.border_radius}px`};
      }
    }
    
    .fields {
      display: flex;
      flex-direction: column;
      gap: 16px;
      
      margin-bottom: 24px;
    }

    .button {
      width: 100%;
    }
`;
