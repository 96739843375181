import React from 'react';
import TextButton from '../../../components/buttons/button/TextButton';
import { useNavigate } from 'react-router-dom';
import ArrowLeftIcon from '../../../assets/icons/errors/ArrowLeftIcon';
import { ErrorStyles } from '../ErrorStyles';
import ErrorIcon from '../../../assets/icons/errors/403/ErrorIcon';

function Error403() {
  const navigate = useNavigate();

  return (
    <ErrorStyles>
      <div className="container">
        <div className="iconWrapper">
          <ErrorIcon />
        </div>

        <div className="textWrapper">
          <span className="error">403</span>
          <h1 className="textBold28">Access Denied</h1>
          <span className="textBase16">Sorry, you don’t have permission to access this page</span>
        </div>
      </div>
    </ErrorStyles>
  );
}

export default Error403;
