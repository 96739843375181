import React, { useState } from 'react';
import { TextAreaStyles } from './TextAreaStyles';
import { InputFormDesignProps } from '../../../enteties/types/forms.types';

interface Props {
  name: string;
  onChange: any;
  styles: InputFormDesignProps;
  label: string;
  value: string;
  placeholder: string;
  disabled?: boolean;
  error?: string | null;
}

function TextArea({
  name, value, styles, label, placeholder, disabled, onChange, error,
}: Props) {
  return (
    <TextAreaStyles styles={styles}>
      <label htmlFor={name}>
        <span className="textSemiBold12">{label}</span>

        <textarea
          name={name}
          id={name}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          rows={4}
          // cols={maxCols}
          // disabled={disabled}
        />
        {error && (
          <div className="errorContainer">
            <span className="textBase10">
              *
              {' '}
              {error}
            </span>
          </div>
        )}
      </label>
    </TextAreaStyles>
  );
}

export default TextArea;
