import React, { useEffect, useState } from 'react';
import { PhoneInputStyles } from './PhoneInputStyles';
import { InputFormDesignProps } from '../../../enteties/types/forms.types';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';

interface Props {
  name: string;
  onChange: any;
  styles: InputFormDesignProps;
  label: string;
  value: string;
  placeholder: string;
  disabled?: boolean;
  error?: string | null;
  defaultCountry?: string;
}

const TelInput = React.memo(({
  name, styles, label, placeholder, disabled, value, onChange, error, defaultCountry,
}: Props) => {
  function handleChange(phone: string) {
    onChange({ target: { name, value: phone } });
  }

  return (
    <PhoneInputStyles styles={styles}>
      <label htmlFor={name}>
        <span className="textSemiBold12">{label}</span>
        <PhoneInput
          defaultCountry={defaultCountry}
          value={value}
          onChange={(phone) => handleChange(phone)}
          placeholder={placeholder}
          disableDialCodePrefill
        />
        {error && (
        <div className="errorContainer">
          <span className="textBase10">
            *
            {' '}
            {error}
          </span>
        </div>
        )}
      </label>
    </PhoneInputStyles>
  );
});

export default TelInput;
