import React, { useEffect, useRef, useState } from 'react';
import { TForm } from '../../enteties/types/forms.types';
import workWithResponse from '../../helpers/workWithResponse';
import { Api } from '../../api';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { FormPageStyles } from './FormPageStyles';
import Loader from '../../components/loader/Loader';
import Form from '../../components/formPage/form/Form';
import Logo from '../../assets/Logo';
import { IpInfo } from '../../enteties/types/ipInfo.types';
import Signature from '../../components/other/signature/Signature';

function FormPage() {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<TForm | null>();
  const [userIpInfo, setUserIpInfo] = useState<IpInfo | undefined>(undefined);

  const { id } = useParams();
  const { pathname } = useLocation();
  const formRef = useRef<HTMLDivElement | null>(null);

  async function getInitialData() {
    try {
      setIsLoading(true);

      if (id && +id) {
        const formDataFromServer = await workWithResponse(() => Api.getForm(+id), true).then((res) => {
          if (res.data) {
            return res.data;
          }
        });

        await fetch('https://ipapi.co/json/')
          .then((res) => res.json())
          .then((response) => {
            setUserIpInfo(response);
          })
          .catch((e) => {
            console.log('Request failed:', e);
          });

        if (formDataFromServer) {
          const formFields = await Promise.all(formDataFromServer.fields.map(async (fieldId) => workWithResponse(() => Api.getFormField(fieldId), true).then((res) => res.data!)));

          const formDataWithFields: TForm = {
            ...formDataFromServer,
            fields: formFields,
          };

          setFormData(formDataWithFields);
        }
      } else {
        navigate('/error/404');
      }
    } catch (e) {
      console.error(`Error: ${e}`);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getInitialData();
  }, []);

  return (
    formData ? (
      <FormPageStyles ref={formRef} styles={formData.design}>
        <div />

        <div className="container">
          <Form userIpInfo={userIpInfo} formData={formData} />
        </div>

        <Signature pageColor={formData.design.page.background_color} />
      </FormPageStyles>
    ) : (
      <div className="pageLoader"><Loader /></div>
    )
  );
}

export default FormPage;
