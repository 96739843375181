import React, { useEffect } from 'react';
import {
  FormFieldsTypes, TApiField, TField, TForm, TFormDesign,
} from '../../../enteties/types/forms.types';
import Input from '../../formComponents/input/Input';
import TextArea from '../../formComponents/textArea/TextArea';
import { FormStyles } from './FormStyles';
import {
  FieldArray, FieldArrayRenderProps, FormikProvider, useFormik,
} from 'formik';
import Button from '../../formComponents/button/Button';
import { TAnswer, TLead } from '../../../enteties/types/lead.types';

import {
  array, lazy, number, object, string,
} from 'yup';
import TelInput from '../../formComponents/phoneInput/TelInput';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { IpInfo } from '../../../enteties/types/ipInfo.types';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { notification } from '../../../helpers/notifications/toastify';
import workWithResponse from '../../../helpers/workWithResponse';
import { Api } from '../../../api';

const phoneUtil = PhoneNumberUtil.getInstance();

const isPhoneValid = (phone: string) => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
};

function getFieldByType({
  name, field, onChange, formDesign, value, error, userIpInfo,
}:{
  field: TApiField, formDesign: TFormDesign, name: string, onChange: any, value: string, error: string | null, userIpInfo: IpInfo | undefined,
}) {
  switch (field.type) {
    case FormFieldsTypes.email:
      return (
        <Input
          value={value}
          name={name}
          label={field.label}
          placeholder={field.placeholder}
          styles={formDesign.inputs}
          error={error}
          onChange={onChange}
        />
      );

    case FormFieldsTypes.phone:
      return (
        <TelInput
          value={value}
          name={name}
          label={field.label}
          placeholder={field.placeholder}
          styles={formDesign.inputs}
          error={error}
          defaultCountry={userIpInfo?.country_code.toLowerCase()}
          onChange={onChange}
        />
      );

    case FormFieldsTypes.text_input:
      return (
        <Input
          value={value}
          name={name}
          label={field.label}
          placeholder={field.placeholder}
          styles={formDesign.inputs}
          error={error}
          onChange={onChange}
        />
      );

    case FormFieldsTypes.textarea:
      return (
        <TextArea
          name={name}
          value={value}
          onChange={onChange}
          styles={formDesign.inputs}
          placeholder={field.placeholder}
          error={error}
          label={field.label}
        />
      );

    default:
      return null;
  }
}

interface FormProps {
  formData: TForm;
  userIpInfo: IpInfo | undefined;
}

function Form({ formData, userIpInfo }: FormProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const formik = useFormik<{fields: TApiField[], answers: TAnswer[]}>({
    initialValues: {
      fields: [],
      answers: [],
    },
    onSubmit: async (values) => {
      localStorage.setItem('thankYouData', JSON.stringify(formData.thank_you));
      const utmCampaign = searchParams.get('utm_campaign');
      const utmMedium = searchParams.get('utm_medium');
      const utmSource = searchParams.get('utm_source');

      try {
        const dataToSubmit: TLead = {
          answers: values.answers,
          form_id: formData.id!,
          price: null,
          status_id: 1,
          utm_campaign: utmCampaign ?? '',
          utm_medium: utmMedium ?? '',
          utm_source: utmSource ?? '',
        };

        const response = await workWithResponse(() => Api.creatLead(dataToSubmit));

        if (response.data) {
          formik.setSubmitting(false);
          navigate('/thank-you');
        } else if (response.error) {
          throw new Error(response.error);
        }
      } catch (e) {
        notification.error('An error occurred while submitting form');
      } finally {
        formik.setSubmitting(false);
      }
    },
    validationSchema: object().shape({
      answers: array().of(
        lazy((item: TAnswer) => {
          const schema = object().shape({
            field_id: number(),
            type: number(),
            value: string(),
          });

          const { field_id, type } = item;
          const field = formData.fields.find(({ id }) => id === field_id);

          if (field) {
            const stringFields = [FormFieldsTypes.phone, FormFieldsTypes.text_input, FormFieldsTypes.textarea, FormFieldsTypes.email];

            if (stringFields.includes(type)) {
              let validation = string();

              if (type === FormFieldsTypes.email) {
                validation = validation.concat(validation.email('Incorrect email'));
              }

              if (type === FormFieldsTypes.phone) {
                validation = validation.concat(validation.test('phone', 'Phone is invalid', (val) => isPhoneValid(val || '')));
              }

              if (field.validation.required) {
                validation = validation.concat(validation.required('Required field'));
              }

              return schema.shape({ value: validation });
            }
          }

          return schema;
        }),
      ),
    }),
  });

  function generateAnswers(fields: TApiField[]) {
    const answers: TAnswer[] = [];

    fields.forEach((field) => {
      if (field.type === FormFieldsTypes.email
        || field.type === FormFieldsTypes.textarea
        || field.type === FormFieldsTypes.text_input
        || field.type === FormFieldsTypes.phone
      ) {
        answers.push({ value: '', type: field.type, field_id: field.id! });
      }
    });

    return answers;
  }

  useEffect(() => {
    formik.setValues({ fields: formData.fields, answers: generateAnswers(formData.fields) });
  }, [formData]);

  useEffect(() => {
    const resizeIframe = () => {
      const height = document.documentElement.scrollHeight;
      const width = document.documentElement.scrollWidth;

      window.parent.postMessage(
        {
          type: 'resizeIframe',
          height,
          width,
        },
        '*',
      );
    };

    // Викликаємо одразу при завантаженні сторінки
    resizeIframe();

    // Слухаємо зміни розміру (наприклад, при взаємодії користувача)
    window.addEventListener('resize', resizeIframe);

    return () => {
      window.removeEventListener('resize', resizeIframe);
    };
  }, [formData]);

  return (
    <FormikProvider value={formik}>
      <FormStyles onSubmit={formik.handleSubmit} styles={formData.design} className="form">
        <div className="formHead">
          {formData.design.image.url && (
            <div>
              <img className="image" src={formData.design.image.url} alt="" />
            </div>
          )}

          <h1 className="title">{formData.title}</h1>

          <span className="textBase16 description">{formData.description}</span>
        </div>

        <div className="fieldsWrapper">
          <FieldArray name="fields">
            {(arrayHelpers: FieldArrayRenderProps) => (
              <div className="fields">
                {formik.values.fields.map((field, index) => (
                  <div key={field.id}>
                    {getFieldByType({
                      field,
                      formDesign: formData.design,
                      name: `answers.${index}.value`,
                      onChange: formik.handleChange,
                      value: formik.values.answers[index].value || '',
                      userIpInfo,
                      // @ts-ignore
                      error: formik.touched.fields && formik.touched.fields[index] && formik.errors.answers && formik.errors.answers[index] && formik.errors.answers[index].value ? formik.errors.answers[index].value : null,
                    })}
                  </div>
                ))}
              </div>
            )}
          </FieldArray>
        </div>

        <Button
          styles={{ submit_button: formData.design.submit_button }}
          text={formData.submit_button.text}
          isLoading={formik.isSubmitting}
        />
      </FormStyles>
    </FormikProvider>
  );
}

export default Form;
