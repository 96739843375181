import React from 'react';

function ErrorIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="500"
      height="371"
      fill="none"
      viewBox="0 0 500 371"
    >
      <path
        fill="#8C8C8C"
        d="M114.691 90.88c-15.32 40.81-2.09 95.9 21.58 139 61 111.9 209.44 48.63 206.88-2.19 3.48-48.48-40.12-62.88-54.91-90.06-82.82-153.42-161.24-81.16-173.55-46.75z"
      />
      <path
        fill="#fff"
        d="M114.691 90.88c-15.32 40.81-2.09 95.9 21.58 139 61 111.9 209.44 48.63 206.88-2.19 3.48-48.48-40.12-62.88-54.91-90.06-82.82-153.42-161.24-81.16-173.55-46.75z"
        opacity="0.9"
      />
      <path
        fill="#00C8CB"
        d="M157.68 232.64H96.83v-27.47l60.85-72.34h29.12v73.9h15.09v25.91H186.8v22.48h-29.12v-22.48zm0-25.91v-37.84l-32.16 37.84h32.16zM212.301 194.51c0-22.86 4.113-38.86 12.34-48 8.226-9.14 20.763-13.707 37.61-13.7 8.086 0 14.73 1 19.93 3a36.796 36.796 0 0112.71 7.79 41.613 41.613 0 017.75 10.09 52.426 52.426 0 014.55 12.34 115.419 115.419 0 013.36 28c0 21.813-3.69 37.777-11.07 47.89-7.38 10.113-20.09 15.173-38.13 15.18-10.12 0-18.297-1.613-24.53-4.84a39.754 39.754 0 01-15.33-14.19c-2.9-4.427-5.157-10.483-6.77-18.17a124.313 124.313 0 01-2.42-25.39zm33.14.08c0 15.333 1.35 25.79 4.05 31.37 2.7 5.58 6.623 8.383 11.77 8.41a12.347 12.347 0 008.82-3.57c2.493-2.38 4.326-6.14 5.5-11.28 1.173-5.14 1.76-13.14 1.76-24 0-15.96-1.354-26.69-4.06-32.19-2.707-5.5-6.767-8.247-12.18-8.24-5.52 0-9.52 2.803-12 8.41-2.48 5.607-3.7 15.97-3.66 31.09zM381.741 232.64h-60.85v-27.47l60.85-72.34h29.12v73.9h15.14v25.91h-15.14v22.48h-29.12v-22.48zm0-25.91v-37.84l-32.15 37.84h32.15z"
      />
      <g fill="#00C8CB" opacity="0.3">
        <path d="M211 90.62a1.87 1.87 0 11-1.86-1.87 1.859 1.859 0 011.86 1.87zM82.97 162.45a1.32 1.32 0 100-2.64 1.32 1.32 0 000 2.64zM301.05 355.22a1.89 1.89 0 100-3.78 1.89 1.89 0 000 3.78zM346.5 278.32a1.32 1.32 0 100-2.64 1.32 1.32 0 000 2.64zM434.171 40.62a1.32 1.32 0 11-2.639.002 1.32 1.32 0 012.639-.002zM182.751 14a1.32 1.32 0 11-1.32-1.32 1.33 1.33 0 011.32 1.32zM287.701 83.26a1.32 1.32 0 100-2.64 1.32 1.32 0 000 2.64z" />
      </g>
      <path
        fill="#00C8CB"
        d="M151.23 83.27c12.101 0 21.91-9.81 21.91-21.91s-9.809-21.91-21.91-21.91c-12.1 0-21.91 9.81-21.91 21.91s9.81 21.91 21.91 21.91z"
      />
      <path
        fill="#fff"
        d="M151.23 83.27c12.101 0 21.91-9.81 21.91-21.91s-9.809-21.91-21.91-21.91c-12.1 0-21.91 9.81-21.91 21.91s9.81 21.91 21.91 21.91z"
        opacity="0.7"
      />
      <path
        fill="#00C8CB"
        d="M143.68 44.83A21.84 21.84 0 00135 46.6a21.916 21.916 0 00-1.636 27.475 21.915 21.915 0 0026.506 7.415 21.92 21.92 0 00-16.23-36.65l.04-.01z"
        opacity="0.2"
      />
      <path
        fill="#00C8CB"
        d="M141.5 50.62a1.998 1.998 0 01-2.39 1.962 1.997 1.997 0 01-1.572-1.572 2.005 2.005 0 01.851-2.053 1.998 1.998 0 013.111 1.663zM165.061 48.62a1.999 1.999 0 01-1.235 1.848 2.003 2.003 0 01-2.18-.434 2.003 2.003 0 01-.433-2.18 1.995 1.995 0 011.848-1.234 2 2 0 012 2zM161.06 62.9a3.276 3.276 0 01-2.025 3.03 3.28 3.28 0 112.025-3.03zM150.641 72.25a4.382 4.382 0 01-5.235 4.296 4.378 4.378 0 01-3.192-5.972 4.384 4.384 0 014.047-2.704 4.378 4.378 0 014.38 4.38z"
        opacity="0.2"
      />
      <path
        fill="#00C8CB"
        d="M410.9 325.646c2.439-10.336-3.962-20.693-14.299-23.133-10.336-2.44-20.693 3.962-23.133 14.298-2.44 10.337 3.962 20.694 14.299 23.134 10.336 2.439 20.693-3.962 23.133-14.299z"
      />
      <path
        fill="#fff"
        d="M410.9 325.646c2.439-10.336-3.962-20.693-14.299-23.133-10.336-2.44-20.693 3.962-23.133 14.298-2.44 10.337 3.962 20.694 14.299 23.134 10.336 2.439 20.693-3.962 23.133-14.299z"
        opacity="0.3"
      />
      <path
        fill="#00C8CB"
        d="M404.33 306.34a19.221 19.221 0 00-17.67 33.32c1.793.545 3.657.821 5.53.82a19.227 19.227 0 0018.115-12.787 19.235 19.235 0 00-5.975-21.353z"
        opacity="0.4"
      />
      <path
        fill="#00C8CB"
        d="M373.83 327c-20.53 9.66-5.22 17.11 23.71 6.71 26.79-9.63 37-21.77 13-18 1.29 5.05-32.26 18.12-36.71 11.29z"
      />
      <path
        fill="#000"
        d="M404.1 132.83C377.21 151 342.4 175 332.79 232.64h-2.05c9.35-57 42.89-81.57 69.79-99.81h3.57z"
        opacity="0.2"
      />
      <path
        fill="#3D8B9E"
        d="M265.001 313.27c-17 0-33.81-7.67-42-20.19-5.05-7.74-10.92-23.95 6.56-45.58l1.55 1.26c-12.36 15.3-14.64 30.65-6.43 43.23 10 15.3 33.59 23 53.73 17.52 20.63-5.61 33.15-23.55 34.36-49.22 4.13-87.81 50.78-114.86 84.84-134.61 21.17-12.27 36.46-21.13 33.1-39.84-.47-2.59-1.5-4.38-3.17-5.48-4.35-2.87-12.85-.88-22.69 1.41-19.31 4.5-45.75 10.66-61.5-16.13l1.73-1c15 25.53 39.57 19.8 59.32 15.2 10.29-2.39 19.17-4.46 24.24-1.13 2.15 1.41 3.47 3.64 4 6.8 3.61 20.08-13 29.72-34.05 41.92-33.67 19.52-79.77 46.25-83.85 133-1.26 26.6-14.32 45.21-35.84 51.06a52.892 52.892 0 01-13.9 1.78z"
      />
      <path
        fill="#fff"
        d="M265.001 313.27c-17 0-33.81-7.67-42-20.19-5.05-7.74-10.92-23.95 6.56-45.58l1.55 1.26c-12.36 15.3-14.64 30.65-6.43 43.23 10 15.3 33.59 23 53.73 17.52 20.63-5.61 33.15-23.55 34.36-49.22 4.13-87.81 50.78-114.86 84.84-134.61 21.17-12.27 36.46-21.13 33.1-39.84-.47-2.59-1.5-4.38-3.17-5.48-4.35-2.87-12.85-.88-22.69 1.41-19.31 4.5-45.75 10.66-61.5-16.13l1.73-1c15 25.53 39.57 19.8 59.32 15.2 10.29-2.39 19.17-4.46 24.24-1.13 2.15 1.41 3.47 3.64 4 6.8 3.61 20.08-13 29.72-34.05 41.92-33.67 19.52-79.77 46.25-83.85 133-1.26 26.6-14.32 45.21-35.84 51.06a52.892 52.892 0 01-13.9 1.78z"
        opacity="0.2"
      />
      <path
        fill="#3D8B9E"
        d="M322.762 42c4.607.027 9.185.745 13.58 2.13 0 0 11 18.77 12.3 23.07-.46 4.24-7.61 11.19-7.61 11.19L322.762 42z"
      />
      <path
        fill="#fff"
        d="M322.762 42c4.607.027 9.185.745 13.58 2.13 0 0 11 18.77 12.3 23.07-.46 4.24-7.61 11.19-7.61 11.19L322.762 42z"
        opacity="0.3"
      />
      <path
        fill="#3D8B9E"
        d="M355.341 133.13a141.418 141.418 0 01-11.56-16.38 82.743 82.743 0 01-2.39-4.42c-.43-.85-.84-1.7-1.24-2.56a10.76 10.76 0 01-1.21-2.69c-1.2-12.67 3.14-22-1-32.17l-16.48 6.44s1.4 18.12 4.6 29c2 6.73 6.48 12.55 10.81 17.94 1.35 1.68 2.65 3.41 4 5.1 1.35 1.69 2.71 3.06 4 4.65 1.95 2.41 2.59 4.72 1.12 7.56l-.25.45c-.42.74 1.54 1.58 2.78 0 2-2.58 1.72-2.42 3.46-4.62 1.06-1.33 2.27-2.78 3.32-4a3.372 3.372 0 00.04-4.3z"
      />
      <path
        fill="#fff"
        d="M355.341 133.13a141.418 141.418 0 01-11.56-16.38 82.743 82.743 0 01-2.39-4.42c-.43-.85-.84-1.7-1.24-2.56a10.76 10.76 0 01-1.21-2.69c-1.2-12.67 3.14-22-1-32.17l-16.48 6.44s1.4 18.12 4.6 29c2 6.73 6.48 12.55 10.81 17.94 1.35 1.68 2.65 3.41 4 5.1 1.35 1.69 2.71 3.06 4 4.65 1.95 2.41 2.59 4.72 1.12 7.56l-.25.45c-.42.74 1.54 1.58 2.78 0 2-2.58 1.72-2.42 3.46-4.62 1.06-1.33 2.27-2.78 3.32-4a3.372 3.372 0 00.04-4.3z"
        opacity="0.7"
      />
      <path
        fill="#3D8B9E"
        d="M351.31 127.92a54.655 54.655 0 01-8.66 7.52c.43.48.85 1 1.28 1.46a43.89 43.89 0 008.5-7.51l-1.12-1.47zM355.342 133.13l-.12-.14c-.77.883-1.218 2-1.27 3.17a4.993 4.993 0 00.38 2.35l.95-1.13a3.373 3.373 0 00.06-4.25z"
        opacity="0.3"
      />
      <path
        fill="#3D8B9E"
        d="M318.841 54a35.402 35.402 0 01-6.37 7.19 23.282 23.282 0 01-4.42 3c-.832.426-1.694.79-2.58 1.09l-.68.22-.22.06-.47.13c-.29.069-.584.115-.88.14a7.55 7.55 0 01-2.51-.23 12.228 12.228 0 01-2.94-1.27 24.915 24.915 0 01-2.15-1.41 40.321 40.321 0 01-3.58-3 53.094 53.094 0 01-6-6.74 2.508 2.508 0 011.687-3.98 2.51 2.51 0 011.663.36h.08c2.36 1.5 4.74 3.08 7.06 4.49 1.18.69 2.32 1.39 3.45 1.93.516.27 1.047.51 1.59.72.338.15.701.237 1.07.26.06 0 0-.07-.37-.06a2.92 2.92 0 00-.35 0l-.22.05.33-.17c.444-.24.875-.503 1.29-.79.895-.63 1.731-1.34 2.5-2.12a63.671 63.671 0 004.9-5.79 5 5 0 018 5.93l.12-.01z"
      />
      <path
        fill="#fff"
        d="M318.841 54a35.402 35.402 0 01-6.37 7.19 23.282 23.282 0 01-4.42 3c-.832.426-1.694.79-2.58 1.09l-.68.22-.22.06-.47.13c-.29.069-.584.115-.88.14a7.55 7.55 0 01-2.51-.23 12.228 12.228 0 01-2.94-1.27 24.915 24.915 0 01-2.15-1.41 40.321 40.321 0 01-3.58-3 53.094 53.094 0 01-6-6.74 2.508 2.508 0 011.687-3.98 2.51 2.51 0 011.663.36h.08c2.36 1.5 4.74 3.08 7.06 4.49 1.18.69 2.32 1.39 3.45 1.93.516.27 1.047.51 1.59.72.338.15.701.237 1.07.26.06 0 0-.07-.37-.06a2.92 2.92 0 00-.35 0l-.22.05.33-.17c.444-.24.875-.503 1.29-.79.895-.63 1.731-1.34 2.5-2.12a63.671 63.671 0 004.9-5.79 5 5 0 018 5.93l.12-.01z"
        opacity="0.7"
      />
      <path
        fill="#3D8B9E"
        d="M282.291 47.42l1.17 2s.89 2.62 2.68 3.1l4.86-1.57-.25-.41c-.62-.94-.55-2.77-.34-4.29.21-1.52-.57-1.57-1.15-1.19a3.827 3.827 0 00-.84 1.65 7.659 7.659 0 00-.79-.93l-1.48-1.48a1.718 1.718 0 00-2.34-.06l-1.2 1.07a1.712 1.712 0 00-.32 2.11z"
      />
      <path
        fill="#fff"
        d="M282.291 47.42l1.17 2s.89 2.62 2.68 3.1l4.86-1.57-.25-.41c-.62-.94-.55-2.77-.34-4.29.21-1.52-.57-1.57-1.15-1.19a3.827 3.827 0 00-.84 1.65 7.659 7.659 0 00-.79-.93l-1.48-1.48a1.718 1.718 0 00-2.34-.06l-1.2 1.07a1.712 1.712 0 00-.32 2.11z"
        opacity="0.7"
      />
      <path
        fill="#3D8B9E"
        d="M327.671 40.22a59.644 59.644 0 00-15.34 6.47 4.318 4.318 0 00-1.94 4.53c1.93 9.44 6.32 22.08 11.06 30.13l22.11-9.15c.15-3.9-5.22-16.52-10.69-28.72-.98-2.19-2.87-3.82-5.2-3.26z"
      />
      <path
        fill="#fff"
        d="M327.671 40.22a59.644 59.644 0 00-15.34 6.47 4.318 4.318 0 00-1.94 4.53c1.93 9.44 6.32 22.08 11.06 30.13l22.11-9.15c.15-3.9-5.22-16.52-10.69-28.72-.98-2.19-2.87-3.82-5.2-3.26z"
        opacity="0.8"
      />
      <path
        fill="#3D8B9E"
        d="M336.3 51.21l-4.39-1.47c1 2.57 4.53 5.82 7 7.73-.8-2-1.69-4.1-2.61-6.26z"
        opacity="0.3"
      />
      <path
        fill="#3D8B9E"
        d="M326.221 30.32c-1.83-3.48-5.78-5.23-10.52-4.84-4 .34-7.54 4.42-7.12 6.62.42 2.2 3.78 3.14 4.42 3.9l-2.77 2a3.004 3.004 0 00-1.14 3.216c.106.391.289.756.54 1.074 1.17 1.48 2.71 3 3.6 4.12 7.66-.2 13.33-3.12 15.38-5.93-.77-3.56-.61-6.7-2.39-10.16z"
      />
      <path
        fill="#fff"
        d="M326.221 30.32c-1.83-3.48-5.78-5.23-10.52-4.84-4 .34-7.54 4.42-7.12 6.62.42 2.2 3.78 3.14 4.42 3.9l-2.77 2a3.004 3.004 0 00-1.14 3.216c.106.391.289.756.54 1.074 1.17 1.48 2.71 3 3.6 4.12 7.66-.2 13.33-3.12 15.38-5.93-.77-3.56-.61-6.7-2.39-10.16z"
        opacity="0.8"
      />
      <path
        fill="#263238"
        d="M322.461 32.48a7.572 7.572 0 01-5.729 10.203 7.57 7.57 0 01-4.081-14.503 7.579 7.579 0 019.81 4.3z"
      />
      <path
        fill="#3D8B9E"
        d="M387.391 122.6c-.11-3.29-.26-3-.35-5.77-.06-1.7-.07-3.59-.08-5.22a3.36 3.36 0 00-2.7-3.28c-1.32-.27-2.65-.52-4-.8-1.73-.37-3.44-.77-5.13-1.26-1.32-.38-2.62-.8-3.91-1.27s-2.74-1-4.08-1.62c-1.58-.67-3.14-1.39-4.68-2.14-1.73-.82-3.44-1.68-5.15-2.55-6.58-10.89-6.72-18.07-13.78-26.49l-15.16 6.86s11.14 19.76 18.72 28.14c4.37 4.82 11.22 7 17.33 8.58 4.41 1.13 8.88 2 13.35 2.83 1.74.32 3.63.44 5.13 1.48a5.739 5.739 0 012.14 3.45c.067.28.127.56.18.84.19.84 2.24.2 2.17-1.78z"
      />
      <path
        fill="#fff"
        d="M387.391 122.6c-.11-3.29-.26-3-.35-5.77-.06-1.7-.07-3.59-.08-5.22a3.36 3.36 0 00-2.7-3.28c-1.32-.27-2.65-.52-4-.8-1.73-.37-3.44-.77-5.13-1.26-1.32-.38-2.62-.8-3.91-1.27s-2.74-1-4.08-1.62c-1.58-.67-3.14-1.39-4.68-2.14-1.73-.82-3.44-1.68-5.15-2.55-6.58-10.89-6.72-18.07-13.78-26.49l-15.16 6.86s11.14 19.76 18.72 28.14c4.37 4.82 11.22 7 17.33 8.58 4.41 1.13 8.88 2 13.35 2.83 1.74.32 3.63.44 5.13 1.48a5.739 5.739 0 012.14 3.45c.067.28.127.56.18.84.19.84 2.24.2 2.17-1.78z"
        opacity="0.8"
      />
      <path
        fill="#3D8B9E"
        d="M379.7 107.4c-.6-.13-1.2-.26-1.81-.41.05 3.46-1.57 9.42-2.16 11.23l1.9.36a38.08 38.08 0 002.07-11.18zM387.001 111.61a3.359 3.359 0 00-2.69-3.28l-1-.19a4.574 4.574 0 001.63 2.9c.57.528 1.255.918 2 1.14.06-.18.06-.38.06-.57z"
        opacity="0.3"
      />
      <path
        fill="#fff"
        d="M321.052 32.54c.4 1.52-1.3 3.11-2.65 1.8a30.842 30.842 0 00-4.12-3.69c-1.39-.87.46-2.39 2.65-1.8a5.937 5.937 0 014.12 3.69z"
      />
      <path
        fill="#3D8B9E"
        d="M321.16 80.86c-.7.26.58 1.46.58 1.46s14-4.79 22.5-9.72a1.877 1.877 0 00-.68-1.58 215.872 215.872 0 01-22.4 9.84z"
      />
      <path
        fill="#fff"
        d="M321.16 80.86c-.7.26.58 1.46.58 1.46s14-4.79 22.5-9.72a1.877 1.877 0 00-.68-1.58 215.872 215.872 0 01-22.4 9.84z"
        opacity="0.5"
      />
      <path
        fill="#3D8B9E"
        d="M331.461 39.56c2.76 1.4 5.35 2.87 8 4.5 1.29.82 2.57 1.65 3.84 2.55 1.27.9 2.53 1.82 3.8 2.86l.47.39.59.54c.351.315.685.648 1 1 .32.35.59.69.85 1 .26.31.54.68.77 1a43.805 43.805 0 012.58 4 59.063 59.063 0 014 8.35 2.52 2.52 0 01-4.19 2.62l-.05-.06c-2-2.13-3.93-4.37-5.87-6.46s-3.91-4.21-5.54-5.14c-2.27-1.41-4.8-2.82-7.31-4.2l-7.56-4.2a5.002 5.002 0 014.68-8.84l-.06.09z"
      />
      <path
        fill="#fff"
        d="M331.461 39.56c2.76 1.4 5.35 2.87 8 4.5 1.29.82 2.57 1.65 3.84 2.55 1.27.9 2.53 1.82 3.8 2.86l.47.39.59.54c.351.315.685.648 1 1 .32.35.59.69.85 1 .26.31.54.68.77 1a43.805 43.805 0 012.58 4 59.063 59.063 0 014 8.35 2.52 2.52 0 01-4.19 2.62l-.05-.06c-2-2.13-3.93-4.37-5.87-6.46s-3.91-4.21-5.54-5.14c-2.27-1.41-4.8-2.82-7.31-4.2l-7.56-4.2a5.002 5.002 0 014.68-8.84l-.06.09z"
        opacity="0.8"
      />
      <path
        fill="#3D8B9E"
        d="M359.732 70.74l-.85-2.13s-.47-2.72-2.16-3.48l-5 .79.17.44c.46 1 .11 2.83-.34 4.29s.31 1.65.95 1.36c.36-.16.71-.81 1.09-1.5.186.348.397.682.63 1l1.23 1.69a1.72 1.72 0 002.3.44l1.36-.87a1.704 1.704 0 00.62-2.03z"
      />
      <path
        fill="#fff"
        d="M359.732 70.74l-.85-2.13s-.47-2.72-2.16-3.48l-5 .79.17.44c.46 1 .11 2.83-.34 4.29s.31 1.65.95 1.36c.36-.16.71-.81 1.09-1.5.186.348.397.682.63 1l1.23 1.69a1.72 1.72 0 002.3.44l1.36-.87a1.704 1.704 0 00.62-2.03z"
        opacity="0.8"
      />
      <path
        fill="#3D8B9E"
        d="M327.241 51.06l-1.22.1-7.49 18.08c.41.03.821-.004 1.22-.1 0 0 9.76-3.64 12.71-5-1.98-4.09-5.22-13.08-5.22-13.08z"
        opacity="0.3"
      />
      <path
        fill="#fff"
        d="M313.57 55.8c.91 4.726 2.6 9.268 5 13.44 3.66-1.26 9.76-3.64 12.72-5A135.55 135.55 0 01326 51.16c-3.13.21-10 2.84-12.43 4.64z"
      />
      <path
        fill="#3D8B9E"
        d="M321.001 59.71a2.58 2.58 0 11-4.944-1.477 2.58 2.58 0 014.944 1.477z"
        opacity="0.3"
      />
      <path
        fill="#3D8B9E"
        d="M322.91 56.27a.847.847 0 01-.708.648.852.852 0 01-.966-.918.848.848 0 011.114-.73.839.839 0 01.56 1zM325.15 55.4a.863.863 0 01-.139.322.865.865 0 01-.59.353.85.85 0 01-.891-1.165.832.832 0 01.723-.593.852.852 0 01.897 1.083z"
        opacity="0.6"
      />
      <path
        fill="#3D8B9E"
        d="M328.29 63.19l-9.25 3.65-.55-1.82 9.24-3.65.56 1.82z"
        opacity="0.5"
      />
      <path
        fill="#000"
        d="M277.259 202.17a94.066 94.066 0 01-1.68 17.35c-1.18 5.14-3.014 8.9-5.5 11.28a12.3 12.3 0 01-8.81 3.57c-5.14 0-9.064-2.803-11.77-8.41a23.714 23.714 0 01-1.21-3.11 144.198 144.198 0 00-15.92 16l-5.31 6.26a38.618 38.618 0 009.77 7.19c6.226 3.22 14.4 4.833 24.52 4.84 1.78 0 3.5-.05 5.17-.15a143.446 143.446 0 0015.1-29l14.85-38.72-19.21 12.9z"
        opacity="0.2"
      />
      <path
        fill="#005273"
        d="M143.389 255l17.5 17.5 49-46.17c-16.89-6.45-49.73 11.86-66.5 28.67zM204.42 316c-5.59-5.6-17.5-17.5-17.5-17.5l46.17-49c6.41 16.85-11.9 49.72-28.67 66.5z"
      />
      <path
        fill="#FC812B"
        d="M271.398 205.7l19.09-36.81-36.81 19.11a144.241 144.241 0 00-32.44 23l-62.75 59.07 30.83 30.83 59.07-62.75a144.211 144.211 0 0023.01-32.45z"
      />
      <path
        fill="#fff"
        d="M271.398 205.7l19.09-36.81-36.81 19.11a144.241 144.241 0 00-32.44 23l-62.75 59.07 30.83 30.83 59.07-62.75a144.211 144.211 0 0023.01-32.45z"
        opacity="0.6"
      />
      <path
        fill="#fff"
        d="M232.199 240.08c7.124 0 12.9-5.776 12.9-12.9 0-7.124-5.776-12.9-12.9-12.9-7.125 0-12.9 5.776-12.9 12.9 0 7.124 5.775 12.9 12.9 12.9z"
      />
      <path
        fill="#FC812B"
        d="M232.198 235.47a8.29 8.29 0 100-16.58 8.29 8.29 0 000 16.58z"
      />
      <path
        fill="#FC812B"
        d="M199.749 289.82l-30.19-30.19 24.72-23.26 28.73 28.74-23.26 24.71z"
        opacity="0.3"
      />
      <path
        fill="#FC812B"
        d="M150.219 282.62c-22.6 1.83-30.09 16.3-32.65 35.53-1.3 9.81-1.88 19.74-10.11 25.48a2.768 2.768 0 00-.044 4.551 2.768 2.768 0 001.674.509c30.34-.95 44.49-15.8 46.27-22a43.074 43.074 0 01-2.49 9.47 2.76 2.76 0 004 3.39c8.51-5.33 19.19-15.15 19.9-31.08-6.26-8.87-26.55-25.85-26.55-25.85z"
      />
      <path
        fill="#fff"
        d="M150.219 282.62c-22.6 1.83-30.09 16.3-32.65 35.53-1.3 9.81-1.88 19.74-10.11 25.48a2.768 2.768 0 00-.044 4.551 2.768 2.768 0 001.674.509c30.34-.95 44.49-15.8 46.27-22a43.074 43.074 0 01-2.49 9.47 2.76 2.76 0 004 3.39c8.51-5.33 19.19-15.15 19.9-31.08-6.26-8.87-26.55-25.85-26.55-25.85z"
        opacity="0.2"
      />
      <path
        fill="#FC812B"
        d="M180.278 315.3l-36.2-36.2 19.28-4.17 21.09 21.09-4.17 19.28z"
      />
    </svg>
  );
}

export default ErrorIcon;
