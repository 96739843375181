import React, { useContext, useEffect } from 'react';
import TextButton from '../../../components/buttons/button/TextButton';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import ArrowLeftIcon from '../../../assets/icons/errors/ArrowLeftIcon';
import { ErrorStyles } from '../ErrorStyles';
import ErrorIcon from '../../../assets/icons/errors/404/ErrorIcon';

function Error404() {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <ErrorStyles>
      <div className="container">
        <div className="iconWrapper">
          <ErrorIcon />
        </div>

        <div className="textWrapper">
          <h1 className="textBold28">Page not found</h1>
          <span className="textBase16">
            Sorry, the page you are looking for doesn’t exist or has been removed.
            <br />
            Let’s find a better place for you to go.
          </span>
        </div>
      </div>
    </ErrorStyles>
  );
}

export default Error404;
