import React, { useEffect, useState } from 'react';
import { TForm, TThankYou } from '../../enteties/types/forms.types';
import workWithResponse from '../../helpers/workWithResponse';
import { Api } from '../../api';
import { useNavigate, useParams } from 'react-router-dom';
import { ThankYouStyles } from './ThankYouStyles';
import Loader from '../../components/loader/Loader';
import Form from '../../components/formPage/form/Form';
import Signature from '../../components/other/signature/Signature';

function ThankYou() {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [thankYouData, setThankYouData] = useState<TThankYou | null>();

  async function getInitialData() {
    try {
      setIsLoading(true);

      const thankYouData = localStorage.getItem('thankYouData');

      console.log(thankYouData);

      // localStorage.removeItem('thankYouData');

      if (thankYouData) {
        setThankYouData(JSON.parse(thankYouData));
      } else {
        navigate('/error/404');
      }
    } catch (e) {
      console.error(`Error: ${e}`);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getInitialData();
  }, []);

  return (
    thankYouData ? (
      <ThankYouStyles styles={thankYouData}>
        <div />

        <div className="container">
          <div className="thankYouBody">
            <div className="head">
              {thankYouData.image.url && (
                <div>
                  <img className="image" src={thankYouData.image.url} alt="" />
                </div>
              )}

              <h1 className="title">{thankYouData.title.text}</h1>

              <span className="textBase16 description">{thankYouData.description.text}</span>
            </div>
          </div>
        </div>

        <Signature pageColor={thankYouData.page.background_color} />
      </ThankYouStyles>
    ) : (
      <div className="pageLoader"><Loader /></div>
    )
  );
}

export default ThankYou;
