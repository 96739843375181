import { RGBA } from '../enteties/types/forms.types';

export const isColorDark = (color: RGBA): boolean => {
  const brightness = (color.r * 299 + color.g * 587 + color.b * 114) / 1000;
  return brightness < 128;
};

export const darkenColor = (color: RGBA, amount: number): string => {
  const {
    r, g, b, a,
  } = color;

  if (r < 30 && g < 30 && b < 30) {
    const adjustedR = Math.min(255, Math.floor(25 + amount * 230));
    const adjustedG = Math.min(255, Math.floor(25 + amount * 230));
    const adjustedB = Math.min(255, Math.floor(25 + amount * 230));
    return `rgba(${adjustedR}, ${adjustedG}, ${adjustedB}, ${a})`;
  }

  const isDark = isColorDark(color);

  const factor = isDark ? 1 + amount : 1 - amount;

  const adjustedR = Math.min(255, Math.max(0, Math.floor(r * factor)));
  const adjustedG = Math.min(255, Math.max(0, Math.floor(g * factor)));
  const adjustedB = Math.min(255, Math.max(0, Math.floor(b * factor)));

  return `rgba(${adjustedR}, ${adjustedG}, ${adjustedB}, ${a})`;
};
