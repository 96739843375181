import React from 'react';
import MoonLoader from 'react-spinners/MoonLoader';
import { LoaderStyles } from './LoaderStyles';

interface LoaderProps {
  color?: string;
  size?: number;
}

function Loader({ color, size }: LoaderProps) {
  return (
    <LoaderStyles className="loaderItem">
      <MoonLoader
        color={color || '#005273'}
        size={size || 100}
      />
    </LoaderStyles>
  );
}

export default Loader;
