import styled from 'styled-components';
import { TFormDesign } from '../../../enteties/types/forms.types';
import { convertColorToCss } from '../../../utils/convertRGBAToCssColor';
import { darkenColor } from '../../../utils/darkenColor';

interface ButtonStylesProps {
  styles: Pick<TFormDesign, 'submit_button'>;
}

export const ButtonStyles = styled.button<ButtonStylesProps>`
  padding: 8px 40px;
  
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  border-radius: ${(props) => `${props.styles.submit_button.border_radius}px`};
  color: ${(props) => convertColorToCss(props.styles.submit_button.text_color)};
  background: ${(props) => convertColorToCss(props.styles.submit_button.background_color)};
  
  transition: .3s ease background-color;
  
  &:hover {
    background-color: ${(props) => darkenColor(props.styles.submit_button.background_color, 0.1)};
  }
  
  &:active {
    background-color: ${(props) => darkenColor(props.styles.submit_button.background_color, 0.2)};
  }
  
  .loaderItem {
    width: fit-content;
  }
  
  svg {
    transition: 0.3s ease transform;
    
    path {
      transition: 0.3s ease all;
    }
  }
`;
