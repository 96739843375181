import React from 'react';
import { InputStyles } from './InputStyles';
import { InputFormDesignProps } from '../../../enteties/types/forms.types';

interface Props {
  name: string;
  onChange: any;
  styles: InputFormDesignProps;
  label: string;
  value: string;
  placeholder: string;
  disabled?: boolean;
  error?: string | null;
}

const Input = React.memo(({
  name, styles, label, placeholder, disabled, value, onChange, error,
}: Props) => (
  <InputStyles styles={styles}>
    <label htmlFor={name}>
      <span className="textSemiBold12">{label}</span>
      <input id={name} onChange={onChange} value={value} type="text" placeholder={placeholder} disabled={disabled} />
      {error && (
        <div className="errorContainer">
          <span className="textBase10">
            *
            {' '}
            {error}
          </span>
        </div>
      )}
    </label>
  </InputStyles>
));

export default Input;
