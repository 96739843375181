import styled from 'styled-components';
import { TFormDesign, TThankYou } from '../../enteties/types/forms.types';
import { convertColorToCss } from '../../utils/convertRGBAToCssColor';

interface FormStylesProps {
  styles: TThankYou;
}

export const ThankYouStyles = styled.div<FormStylesProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  min-width: 100svw;
  min-height: 100svh;
  box-sizing: border-box;
  padding: 20px 0;

  background-color: ${(props) => convertColorToCss(props.styles.page.background_color)};
  
  .signature {
    display: flex;
    align-items: center;
    gap: 8px;
    
    padding: 4px;
    border-radius: 4px;
    
    background: #FFF;

    color: #000;
    
    svg {
      height: 25px;
      width: auto;
    }
  }
  
  .thankYouBody {
      margin: auto;
    
    max-width: 600px;

    padding: 24px;

    border-radius: ${(props) => `${props.styles.form.border_radius}px`};
    overflow: hidden;
    border: 1px solid var(--Stroke, #EEE);
    background: ${(props) => convertColorToCss(props.styles.form.background_color)};
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.12);

    .head {
      display: flex;
      flex-direction: column;
      gap: 6px;

      margin-bottom: 10px;

      text-align: center;

      .title {
        color: ${(props) => convertColorToCss(props.styles.title.color)};

        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 34px;
      }

      .description {
        color: ${(props) => convertColorToCss(props.styles.description.color)};
      }

      .image {
        max-width: 100%;
        max-height: 100%;
        width: auto;
        height: auto;

        object-fit: contain;
        border-radius: ${(props) => `${props.styles.image.border_radius}px`};
      }
    }
  }
`;
